import { register } from '../../api/authService';

export interface AnonymousUserConfig {
  MIN_PLAYER_ID: number;
  MAX_PLAYER_ID: number;
  EMAIL_DOMAIN: string;
}

const config: AnonymousUserConfig = {
  MIN_PLAYER_ID: 0,
  MAX_PLAYER_ID: 100000,
  EMAIL_DOMAIN: 'tradingbattlesgenerated.com'
};

export const generateRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateRandomPassword = (): string => {
  return (Math.random() + 16).toString(36).substring(2);
};

export const generateUsername = (playerId: number): string => {
  return `Player${playerId}`;
};

export const generateEmail = (username: string): string => {
  return `${username}@${config.EMAIL_DOMAIN}`;
};

export interface AnonymousUserCredentials {
  username: string;
  email: string;
  password: string;
}

export const generateAnonymousCredentials = (): AnonymousUserCredentials => {
  const playerId = generateRandomNumber(config.MIN_PLAYER_ID, config.MAX_PLAYER_ID);
  const username = generateUsername(playerId);
  const email = generateEmail(username);
  const password = generateRandomPassword();

  return {
    username,
    email,
    password
  };
};

export const registerAnonymousUser = async (): Promise<AnonymousUserCredentials> => {
  const credentials = generateAnonymousCredentials();
  await register(credentials.username, credentials.email, credentials.password, true);
  return credentials;
};
